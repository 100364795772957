<script setup>
import { defineProps } from "vue";
import { useAppLogo } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppLogo";
import { useAppSave } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppSave";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import { useStore } from "@/core/services/store";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import { useArticleImport } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArticleImport";
import { useMigrationIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationIntegration";
import MigrateIntegrationsModal from "@/components/ExternalApps/SalesChannelManagementApp/components/MigrateIntegrationsModal.vue";

const store = useStore();
const { isAppInitialized } = useAppStatus();
const { logo } = useAppLogo();
const { save } = useAppSave();
const { selectedFeature } = useFeatures();
const { finishMigration, migrationStepFinished } = useMigration();
const { showArticleImportPrepareModal, articleImportPending, saveAction } =
  useArticleImport();
const { shopActive, shopImporterActive, show, channels } =
  useMigrationIntegration();

defineProps({
  isDetailPage: {
    type: Boolean,
    default: () => false
  },
  showIntegrationOptions: {
    type: Boolean,
    default: () => false
  }
});
</script>

<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <template v-if="isDetailPage">
      <div class="d-flex">
        <!--   START: BACK BUTTON   -->
        <button
          v-b-popover.hover="$t('salesChannelManagementApp.backToOverview')"
          class=""
          @click="$router.push({ name: 'salesChannelManagementApp' })"
        >
          <i class="fal fa-chevron-left" />
        </button>
        <!--   END: BACK BUTTON   -->
        <div v-if="isAppInitialized" class="h5 mb-0 ml-5 font-weight-bolder">
          {{
            store.getters.selectedIntegration.name.replace(
              store.getters.selectedApp.name,
              ""
            )
          }}
        </div>
      </div>

      <!--    START: ACTIONS & SAVE    -->
      <div
        v-if="isAppInitialized && selectedFeature !== 'journal'"
        class="actions align-self-end d-flex"
      >
        <button
          v-if="selectedFeature === 'migration'"
          class="btn btn-primary mr-2"
          :disabled="
            !migrationStepFinished.export || !migrationStepFinished.import
          "
          @click="finishMigration"
        >
          {{ $t("salesChannelManagementApp.migration.finish") }}
        </button>

        <div v-if="selectedFeature === 'productSelection'" class="btn-group">
          <button
            :disabled="articleImportPending"
            class="btn btn-primary"
            @click="saveAction()"
          >
            {{ $t("salesChannelManagementApp.useFilterForAllProducts") }}
          </button>
          <v-menu
            content-class="bg-white product-selection-filter-menu"
            bottom
            offset-y
          >
            <template #activator="{ on, attrs }">
              <button
                v-bind="attrs"
                type="button"
                class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                v-on="on"
              ></button>
            </template>

            <perfect-scrollbar
              :options="{ wheelPropagation: false }"
              class="scroll h-100 max-height"
            >
              <ul class="navi navi-hover pa-0">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="navi-item"
                >
                  <span
                    class="navi-link cursor-pointer"
                    @click="showArticleImportPrepareModal = true"
                  >
                    {{
                      $t(
                        "salesChannelManagementApp.useFilterForSelectedProducts"
                      )
                    }}
                  </span>
                </li>
              </ul>
            </perfect-scrollbar>
          </v-menu>
        </div>

        <button v-else class="btn btn-primary" @click="save(false)">
          {{ $t("general.save") }}
        </button>
      </div>
      <!--    END: ACTIONS & SAVE    -->
    </template>
    <template v-else>
      <!--   START: LOGO/ADD INTEGRATION   -->
      <div>
        <img :src="logo" alt="" class="shop-logo" />
      </div>
      <div>
        <button
          v-if="(shopActive || shopImporterActive) && channels.length > 0"
          class="btn btn-primary mr-4"
          @click="show = true"
        >
          <i class="fal fa-diagram-next" />
          {{
            $t("salesChannelManagementApp.migration.migrateExistingIntegration")
          }}
        </button>
        <button
          class="btn btn-primary"
          @click="$router.push({ name: 'salesChannelManagementAppWizard' })"
        >
          <i class="fal fa-plus" />
          {{ $t("salesChannelManagementApp.addIntegration") }}
        </button>
      </div>
      <!--   END: LOGO/ADD INTEGRATION   -->
    </template>
    <MigrateIntegrationsModal v-if="show" />
  </div>
</template>

<style lang="scss" scoped>
.shop-logo {
  max-width: 170px;
  width: 100%;
}

.alert {
  margin-bottom: 0;
}

i {
  color: $color-connect-text;
}

.product-selection-filter-menu {
  transform: translateX(
    12px
  ); // We have to move the menu a bit to the right, otherwise there is a small offset
}

@media screen and (max-width: 920px) {
  :deep(.form-helper) {
    .col-8 {
      display: none !important;
    }
  }
}
</style>
